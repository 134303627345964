:root {
  --color09: #990a10;
  --color08: #ed1c24;
  --color07: #fa9a08;
  --color06: #fec608;
  --color05: #fefe00;
  --color04: #c8df12;
  --color03: #72bf44;
  --color02: #3eab76;
  --color01: #00888a;
  --color00: #004365;

  --ral-1027: #9d9101;
  --ral-5009: #025669;
  --ral-5009-transp: #0256692a;
  --ral-9001: rgb(250, 244, 227);


  --color02transpBlack: #2567475a; // (37, 103, 71)
  --color02transp: #3eab761a; // (62, 171, 118)
  --color03transp: #72bf445a;
  --bg-color: #ccdddf;
  /*--footer-bg: #00888a10;*/
  --footer-bg: #ffffff;
  /*--footer-bg: #c8df1220;*/

  --min-width-sm: 576px;
  --min-width-md: 768px;
  --min-width-lg: 992px;
  --min-width-xl: 1200px;

  --spacing-0: 0;
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-8: 2rem;
  --spacing-10: 2.5rem;
  --spacing-12: 3rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  --spacing-32: 8rem;

  --fontSize-00: 0.6rem;
  --fontSize-0: 0.833rem;
  --fontSize-1: 1rem;
  --fontSize-2: 1.2rem;
  --fontSize-3: 1.44rem;
  --fontSize-4: 1.728rem;
  --fontSize-5: 2.074rem;
  --fontSize-6: 2.488rem;
  --fontSize-7: 2.986rem;
}

// Bootstrap 4 grid sizes
@media (min-width: 576px) { // sm
}

@media (min-width: 768px) { // md
}

@media (min-width: 992px) { // lg
}

@media (min-width: 1200px) { // xl
}

div.welkom-page {

}

img.portrait {
  border: 2px solid;
  border-color: var(--ral-1027);
  border-radius: 50%;
  padding: 5px;

  @media (max-width: 992px) {
    height: 80px;
    width: auto;
  }
}

html, body {
  background-color: var(--ral-9001);
  color: #444444;
  font-size: var(--fontSize-1);
  font-family: 'Montserrat', 'Source Sans Pro', Helvetica, Arial, sans-serif;
  min-height: 100%;
  position: relative;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--ral-5009);
}

div.with-bg-img {
  color: var(--ral-9001);
  background-color: var(--ral-5009-transp);
  padding: 3rem;
}

@media (max-width: 900px) {
  //html, body { font-size: 13px; }
  //h1, h2, h3, h4, h5, h6 { font-size: 10px; }
}

@media (max-width: 400px) {
  html, body {
    font-size: 13px;
  }
  //h1, h2, h3, h4, h5, h6 { font-size: 9px; }
}

.display-2 {
  @media (max-width: 900px) {
    font-size: 3.2rem;
  }
  @media (max-width: 600px) {
    font-size: 2.4rem;
  }
  @media (max-width: 400px) {
    font-size: 1.8rem;
  }

  text-shadow: 4px 4px #00000077;
  color: var(--ral-9001);
}

.display-4 {
  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
  @media (max-width: 400px) {
    font-size: 1.0rem;
  }

  text-shadow: 4px 4px #00000077;
  color: var(--ral-9001);
}

.navbar-nav {
  font-size: 1.3rem;
  @media (max-width: 1200px) {
    font-size: 1.0rem;
  }
  @media (max-width: 400px) {
    font-size: 0.9rem;
  }
}

.nav-link {
  color: var(--ral-5009-transp);

  &:hover,
  &:focus {
    color: var(--ral-5009) !important;
  }

  &.disabled {
    color: var(--ral-9001);
  }
}
//
//.navbar-toggler {
//  color: blue;
//}

.navbar-brand {
  //color: #fff;
  color: var(--ral-5009);
  font-size: 1.7rem;
  font-weight: bolder;
  @media (max-width: 1200px) {
    font-size: 1.0rem;
  }
  @media (max-width: 600px) {
    font-size: 0.7rem;
  }
  padding: 15px 30px;
}

nav {
  //background-color: var(--ral-1027);
  background-color: var(--ral-9001);
}

footer {
  background-color: var(--footer-bg);
  position: relative;
  bottom: 0;
  padding-top: 1rem;
  margin-top: 5rem;
  width: 100%;
  font-size: small;
}

#footer-cr {
  background-color: var(--ral-5009);
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #ffffff;
}

#footer-cr a:visited {
  color: var(--color04);
}

#footer-cr a:link {
  color: var(--color04);
}

#brandLogo {
  margin-right: 15px;
}

#brandName {
  //margin-left: 15px;
}

.jumbotron {
  background-color: #ffffff55;
}

.jt-layer {
  background-color: #ccdddfcc;
}

.link {
  color: var(--ral-5009);
}

a:link, a:visited {
  color: var(--ral-5009);
  text-decoration: none;
}

a:hover {
  color: var(--ral-5009);
  text-decoration: none;
}

p.intro {
  font-size: var(--fontSize-2);
  font-weight: bold;
}

p.question {
  font-style: italic;
}

p.highlight {
  font-style: italic;
  color: var(--ral-1027);
  padding: var(--spacing-2) var(--spacing-2) var(--spacing-2) var(--spacing-2);
  margin-left: 5rem;
}
